.header {
    background-color: #2c3e50;
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    text-decoration: none;
  }
  
  .navLink {
    color: white;
    text-decoration: none;
    margin-left: 1rem;
  }
  
  .navLink:hover {
    text-decoration: underline;
  }