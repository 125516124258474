.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.intro {
  margin-bottom: 3rem;
  text-align: center;
}

.intro h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
}

.intro p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .home {
    padding: 1rem;
  }

  .intro h1 {
    font-size: 2rem;
  }

  .intro p {
    font-size: 1rem;
  }
}