.notFound {
    text-align: center;
    padding: 2rem;
  }
  
  .homeLink {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #3498db;
    color: white;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .homeLink:hover {
    background-color: #2980b9;
  }