.gridContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2rem;
}

.cardLink {
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    display: block;
    width: calc(33.333% - 1.34rem); /* Slight adjustment to prevent wrapping */
    margin-bottom: 2rem; /* Force vertical separation */
}

.cardLink:hover,
.cardLink:focus {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.loading,
.error {
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
}

.error {
    color: #ff0000;
}

@media (max-width: 1200px) {
    .cardLink {
        width: calc(50% - 1rem); /* Adjust for 2 columns with gap */
    }
}

@media (max-width: 768px) {
    .cardLink {
        width: 100%; /* Full width for mobile */
    }
    .gridContainer {
        padding: 1rem;
    }
}