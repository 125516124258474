.card {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure the card takes full height */
    min-height: 250px; /* Set a minimum height for consistency */
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.card:hover {
    background-color: #f8f9fa;
}

.title {
    font-size: 1.3rem;
    margin-bottom: 0.75rem;
    color: #2c3e50;
}

.description {
    font-size: 1rem;
    color: #2c3e50; /* Darker color for better contrast */
    flex-grow: 1; /* Allow description to grow and push the badge to the bottom */
    line-height: 1.5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Limit to 5 lines */
    -webkit-box-orient: vertical;
}

.completedBadge {
    background-color: #2ecc71;
    color: white;
    padding: 0.3rem 0.6rem;
    border-radius: 20px;
    font-size: 0.9rem;
    align-self: flex-start;
    margin-top: auto;
}

.completed {
    background-color: #f0fff0;
}