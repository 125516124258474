.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .main {
    flex: 1;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  @media (max-width: 768px) {
    .main {
      padding: 10px;
    }
  }