.modal {
    background-color: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 2rem auto;
    padding: 2.5rem;
  }
  
  .title {
    color: #2c3e50;
    font-size: 2.2rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #3498db;
    padding-bottom: 0.5rem;
  }
  
  .description {
    color: #34495e;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .articleLink {
    display: inline-block;
    background-color: #3498db;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-decoration: none;
    margin-bottom: 1.5rem;
    transition: background-color 0.3s ease;
  }
  
  .articleLink:hover {
    background-color: #2980b9;
  }
  
  .scenarioIntroduction {
    background-color: #ecf0f1;
    border-left: 4px solid #3498db;
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 0 4px 4px 0;
  }
  
  .scenarioIntroduction h3 {
    color: #2c3e50;
    margin-top: 0;
  }
  
  .navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  
  .navButton, .submitButton {
    background-color: #3498db;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.7rem 1.5rem;
    transition: background-color 0.3s ease, transform 0.1s ease;
  }
  
  .navButton:hover, .submitButton:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
  }
  
  .navButton:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
  
  .submitButton {
    background-color: #2ecc71;
  }
  
  .submitButton:hover {
    background-color: #27ae60;
  }
  
  .progress {
    text-align: center;
    margin-top: 1.5rem;
    color: #7f8c8d;
    font-size: 1.1rem;
  }
  
  .loading {
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
    color: #7f8c8d;
  }
  
  .feedback {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f0f0f0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .feedback h4 {
    margin-top: 0;
    color: #2c3e50;
    font-size: 1.3rem;
  }
  
  .correctAnswer, .selectedAnswer {
    margin-top: 15px;
    padding: 15px;
    border-radius: 8px;
  }
  
  .selectedAnswer {
    background-color: #ffe6e6;
  }
  
  .correctAnswer {
    background-color: #e6ffe6;
  }
  
  .answerFeedback {
    margin-top: 15px;
    padding: 15px;
    border-radius: 8px;
  }
  
  .correctAnswer {
    background-color: #e6ffe6;  /* Light green background */
    border-left: 4px solid #2ecc71;  /* Darker green border */
  }
  
  .incorrectAnswer {
    background-color: #ffe6e6;  /* Light red background */
    border-left: 4px solid #e74c3c;  /* Darker red border */
  }
  
  @media (max-width: 600px) {
    .modal {
      margin: 1rem;
      padding: 1.5rem;
    }
    
    .title {
      font-size: 1.8rem;
    }
  }