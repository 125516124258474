.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.title {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.intro {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.resourceList {
  list-style-type: none;
  padding: 0;
}

.resourceItem {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.resourceTitle {
  font-size: 1.3rem;
  color: #3498db;
  margin-bottom: 0.5rem;
}

.resourceDescription {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.resourceLink {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.resourceLink:hover {
  background-color: #2980b9;
}

.homeLink {
  display: inline-block;
  margin-top: 2rem;
  background-color: #2ecc71;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.homeLink:hover {
  background-color: #27ae60;
}
