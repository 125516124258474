.optionList {
    list-style-type: none;
    padding: 0;
  }
  
  .optionButton {
    width: 100%;
    padding: 1rem;
    margin-bottom: 0.5rem;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: left;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .optionButton:hover {
    background-color: #e0e0e0;
  }
  
  .optionButton.selected {
    background-color: #3498db;
    color: white;
  }